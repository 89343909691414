import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
// import Logo from "../images/Logo_halloween_.svg";
// import Logo from "../images/logo_xmas.svg";
// import Logo from "../images/logo_ua.svg";
import Logo from "../images/logo.svg";
import LogoWhite from "../images/logo-white.svg";
import IconMenu from "../images/icons/interface/menu.svg";
import IconCross from "../images/icons/interface/cross.svg";
import IconCheck from "../images/icons/interface/check.svg";
import IconStar from "../images/icons/theme/general/star.svg";
// import IconBookMenu from '../images/icons/interface/book-menu.svg';
import IconBook from "../images/icons/theme/home/book-open.svg";
import IconDownload from "../images/icons/theme/files/downloaded-file.svg";
import IconCard from "../images/icons/theme/shopping/credit-card.svg";
import IconSmile from "../images/icons/theme/general/smile.svg";
import IconEarth from "../images/icons/theme/home/earth.svg";
import IconChart from "../images/icons/theme/shopping/chart-bar-1.svg";
import IconBuilding from "../images/icons/theme/home/building.svg";
import IconThunder from "../images/icons/theme/general/thunder.svg";
import IconCmd from "../images/icons/theme/code/cmd.svg";
import IconMagic from "../images/icons/theme/design/magic.svg";
import DesignStream from "../images/photos/ux-design-agency-4.jpg";
import UXReview from "../images/photos/ux-review-report.png";
import { SearchNavButton } from "./search/SearchNavButton";
import { useLocation } from "../hooks/useLocation";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import CalendlyPopupButton from "./CalendlyPopupButton";
import { getOrCreateUserId } from "../utils/assignUserId";

const Header = ({ hideHeader, isDirectoryPage = false, isHeaderDark = false, headerBgClass = "bg-white", isScrollUp, isTop, isOpenMobileMenu }) => {
    const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
    const { location, isLoading } = useLocation();
    const easternEuropeanLocations = ["Poland", "Romania", "Hungary", "Czech Republic", "Estonia", "Latvia", "Lithuania", "Serbia", "Brazil", "Chile", "Argentina", "North Macedonia", "Ukraine"];

    const toggleHandleMobileMenu = () => {
        setToggleMobileMenu(!toggleMobileMenu);
        isOpenMobileMenu(!toggleMobileMenu);
    };

    const getHeaderClass = () => {
        if (isHeaderDark) {
            if (toggleMobileMenu) {
                return "navbar-dark bg-primary navbar--mobile-show";
            } else if (!toggleMobileMenu) {
                return `navbar-dark ${headerBgClass}`;
            }
        } else {
            if (toggleMobileMenu) {
                return "navbar-dark bg-primary navbar--mobile-show";
            }
            return `navbar-light ${headerBgClass}`;
        }
    };

    const solutionsRef = useRef(null);
    const industriesRef = useRef(null);
    const aboutUsRef = useRef(null);
    const uxResourcesRef = useRef(null);
    const backDropRef = useRef(null);

    const dropDownSolutionRef = useRef(null);
    const dropDownIndustriesRef = useRef(null);
    const dropDownAboutUsRef = useRef(null);
    const dropDownUxResourcesRef = useRef(null);

    useEffect(() => {
        const elements = [];
        const eventHandlers = [];

        const excludedPaths = ["/how-to-use-chatgpt-api", "/junior-ux-researcher", "/junior-ui-ux-designer-job"];

        // Custom tag logic based on URL
        const addVisitorsIdTag = () => {
            const currentPath = window.location.pathname;
            const shouldExclude = excludedPaths.some(path => currentPath.includes(path));

            if (!shouldExclude) {
                const customTag = document.createElement("script");
                customTag.type = "text/javascript";
                customTag.async = true;
                customTag.innerHTML = `!function () {
                    var reb2b = window.reb2b = window.reb2b || [];
                    if (reb2b.invoked) return;
                    reb2b.invoked = true;
                    reb2b.methods = ["identify", "collect"];
                    reb2b.factory = function (method) {
                        return function () {
                            var args = Array.prototype.slice.call(arguments);
                            args.unshift(method);
                            reb2b.push(args);
                            return reb2b;
                        };
                    };
                    for (var i = 0; i < reb2b.methods.length; i++) {
                        var key = reb2b.methods[i];
                        reb2b[key] = reb2b.factory(key);
                    }
                    reb2b.load = function (key) {
                        var script = document.createElement("script");
                        script.type = "text/javascript";
                        script.async = true;
                        script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz";
                        var first = document.getElementsByTagName("script")[0];
                        first.parentNode.insertBefore(script, first);
                    };
                    reb2b.SNIPPET_VERSION = "1.0.1";
                    reb2b.load("L9NMMZH8MENW");
                }();`;
                document.head.appendChild(customTag);

                const vtagScript = document.createElement("script");
                vtagScript.id = "vtag-ai-js";
                vtagScript.async = true;
                vtagScript.src = "https://r2.leadsy.ai/tag.js";
                vtagScript.setAttribute("data-pid", "1ptFP8LYvA01Eixkl");
                vtagScript.setAttribute("data-version", "062024");
                document.head.appendChild(vtagScript);
            }
        };

        addVisitorsIdTag();

        const updateDropdownPositions = () => {
            // Clear the elements array
            elements.length = 0;
            eventHandlers.length = 0;

            if (solutionsRef.current && dropDownSolutionRef.current) {
                elements.push({ element: solutionsRef.current, dropdown: dropDownSolutionRef.current });
            }

            if (industriesRef.current && dropDownIndustriesRef.current) {
                elements.push({ element: industriesRef.current, dropdown: dropDownIndustriesRef.current });
            }

            if (aboutUsRef.current && dropDownAboutUsRef.current) {
                elements.push({ element: aboutUsRef.current, dropdown: dropDownAboutUsRef.current });
            }

            if (uxResourcesRef.current && dropDownUxResourcesRef.current) {
                elements.push({ element: uxResourcesRef.current, dropdown: dropDownUxResourcesRef.current });
            }

            const backDropRefElement = backDropRef.current;

            let hideTimeout;
            let showTimeout;
            let currentlyOpenDropdown = null;

            if (elements !== null && elements !== undefined) {
                elements.forEach(({ element, dropdown }) => {
                    const liOffset = Math.round(element.getBoundingClientRect().left);
                    //console.log(liOffset);
                    dropdown.style.left = `${-liOffset}px`;

                    const showDropdown = () => {
                        clearTimeout(hideTimeout); // Clear any existing hide timeouts
                        clearTimeout(showTimeout); // Clear any existing show timeouts

                        showTimeout = setTimeout(() => {
                            if (currentlyOpenDropdown && currentlyOpenDropdown !== dropdown) {
                                currentlyOpenDropdown.classList.remove("active"); // hide any other open dropdowns
                            }

                            dropdown.classList.add("active");
                            backDropRefElement.style.display = "block";
                            currentlyOpenDropdown = dropdown;
                        }, 200); // a short delay before showing to prevent accidental opens
                    };

                    const hideDropdown = () => {
                        clearTimeout(showTimeout); // Prevent the dropdown from showing if we quickly hover over another item

                        if (currentlyOpenDropdown === dropdown) {
                            hideTimeout = setTimeout(() => {
                                dropdown.classList.remove("active");
                                backDropRefElement.style.display = "none";
                            }, 200); // a short delay before hiding
                        }
                    };

                    element.addEventListener("mouseover", showDropdown);
                    element.addEventListener("mouseleave", hideDropdown);
                    dropdown.addEventListener("mouseover", showDropdown);
                    dropdown.addEventListener("mouseleave", hideDropdown);

                    // Store the handlers for cleanup later
                    eventHandlers.push({ element, dropdown, showDropdown, hideDropdown });
                });
            }
        };

        // Call updateDropdownPositions right on component mount
        updateDropdownPositions();

        // Set up the handler for when the page fully loads
        const handlePageLoad = () => {
            updateDropdownPositions();
        };

        // Add the event listener to handle resizes after initial setup
        window.addEventListener("resize", updateDropdownPositions);

        // Listen for the full page load event
        if (document.readyState !== "complete") {
            window.addEventListener("load", handlePageLoad);
        }

        // Cleanup function to remove event listener when component is unmounted
        return () => {
            window.removeEventListener("resize", updateDropdownPositions);
            window.removeEventListener("load", handlePageLoad);

            eventHandlers.forEach(({ element, dropdown, showDropdown, hideDropdown }) => {
                element.removeEventListener("mouseover", showDropdown);
                element.removeEventListener("mouseleave", hideDropdown);
                dropdown.removeEventListener("mouseover", showDropdown);
                dropdown.removeEventListener("mouseleave", hideDropdown);
            });
        };
    }, []);

    useEffect(() => {
        const userId = getOrCreateUserId();
        //console.log(`User ID: ${userId}`);
        // You can now use the userId for tracking, sending to analytics, etc.
      }, []);

    return (
        <header className="navbar-container" style={{ zIndex: 9999 }}>
            {!hideHeader && (
            <div
                className={`bg-primary w-100 text-center text-light py-1 news-bar  
                ${isScrollUp ? `${isTop ? "animated fadeIn" : "news-bar--hidden"}` : typeof window !== "undefined" && window.pageYOffset > 73 ? "news-bar--hidden" : ""}
            
            `}
            >
                {isLoading ? (
                    (<> </> /* : easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */ /*: easternEuropeanLocations.includes(location) ? (
                        <>
                            We're hiring a Social Media Expert{" "}
                            <a
                                //href="https://adamfard.freshteam.com/jobs/TBkONWMn2alO/junior-product-designer-ux-ui-remote"
                                href="https://adamfard.freshteam.com/jobs/JrUCef_jGZDk/social-media-manager-remote"
                                className="ml-2 hover-arrow"
                                target="_blank"
                            >
                                {" "}
                                Apply Now
                            </a>
                        </>
                    ) */)
                // ) : location === "United States" || location === "United Kingdom" || location === "Germany" ? (
                //     <>
                //         🔥 Unlimited Design & Dev Services {" "}
                //         <a href="https://adamfard.com/contact-us?utm_source=adamfard.com&utm_medium=header_blue&utm_campaign=unlimited" className="ml-2 hover-arrow">
                //             Learn More
                //         </a>
                //     </>
                ) : (
                    <>
                        {`ChatGPT for UI Design`}{" "}
                        <a href="https://uxpilot.ai?utm_source=adamfard.com&utm_medium=header_blue&utm_campaign=afs" className="ml-2 hover-arrow" target="_blank">
                            {" "}
                            Learn more
                        </a>
                    </>
                )}
            </div>)}
            <nav
                className={`navbar navbar-expand-lg ${getHeaderClass()} 
            ${
                /* animated fadeIn
                 */
                isScrollUp ? `navbar--fixed faster ${!isTop && !hideHeader ? "delay-0s" : hideHeader ? "" : "navbar--back"}` : typeof window !== "undefined" && window.pageYOffset > 73 ? "navbar--hidden" : ""
            }
          `}
                style={hideHeader ? { top: '0px !important' } : {}}

                // ${
                //   isScrollUp
                //     ? `navbar--fixed animated fadeIn faster ${!isTop ? "delay-1s" : ""}`
                //     : typeof window !== "undefined" && window.pageYOffset > 73
                //     ? "navbar--hidden"
                //     : ""
                // }
            >
                <div className="container h-100">
                    {!toggleMobileMenu && (
                        <Link to={isDirectoryPage ? "/agencies" : "/"} className="navbar-brand fade-page" aria-label="adamfard">
                            {isDirectoryPage ? (
                                <span className="h4 align-items-center justify-content-center d-flex mb-0 pb-0"><IconStar className="icon icon-md align-items-left bg-primary-2 mr-1"/>AGENCY Directory</span>
                            ) : (
                                isHeaderDark ? <LogoWhite /> : <Logo />
                            )}
                        </Link>
                    )}

                    {toggleMobileMenu ? (
                        <button className="navbar-toggler button-toggler-close" type="button" aria-label="toggle navbar">
                            <IconCross className="icon icon-md bg-primary-3 navbar-toggler-close" onClick={toggleHandleMobileMenu} />
                        </button>
                    ) : (
                        <button className="navbar-toggler" type="button" aria-label="toggle navbar">
                            <IconMenu className="icon icon-md bg-primary-3 navbar-toggler-open" onClick={toggleHandleMobileMenu} />
                        </button>
                    )}

                    {isDirectoryPage && <CalendlyPopupButton />}

                    {!hideHeader && (
                        <>
                            <div className="navbar--desktop navbar-collapse justify-content-end collapse">
                                <div className="py-2 py-lg-0">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <Link to="/ux-projects" className="nav-link">
                                                Our Projects
                                            </Link>
                                        </li>

                                        {/* <li className="nav-item">
                                            <Link to="/fintech-design" className="nav-link">
                                            Fintech
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/edtech-design" className="nav-link">
                                            Edtech
                                            </Link>
                                        </li> */}

                                        <li className="nav-item dropdown area-hover" ref={solutionsRef}>
                                            <Link to="/#" className="nav-link" style={{ pointerEvents: "none" }}>
                                                Solutions <span className="badge-notification"> </span>
                                                <i className="dd-icon"></i>
                                            </Link>

                                            <div className="dropdown-menu" ref={dropDownSolutionRef}>
                                                <div className="dropdown-menu-content container">
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="dropdown-single-item">
                                                                <Link to="/ux-ui-design-services#mvp" className="list-group-item-action">
                                                                    <div>
                                                                        <span className="hover-arrow">Create MVP</span>
                                                                        <small>From nothing to a research-backed no-code prototype: you'll be sure to impress investors.</small>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                            <div className="dropdown-single-item">
                                                                <Link to="/ux-ui-design-services#scale" className="list-group-item-action">
                                                                    <div>
                                                                        <span className="hover-arrow">Improve Product</span>
                                                                        <small>Reached PMF? We help you to scale your product without scaling the problems.</small>
                                                                    </div>
                                                                </Link>
                                                            </div>

                                                            <div className="dropdown-single-item">
                                                                <Link to="/ux-ui-design-services#research" className="list-group-item-action">
                                                                    <div>
                                                                        <span className="hover-arrow">Customer research</span>
                                                                        <small>Know your (potential) users better than they know themselves.</small>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>

                                                        <div className="col dstream-box">
                                                            <div className="dropdown-single-item h-100">
                                                                <Link to="/unlimited-ux-ui-design-subscription" className="list-group-item-action h-100 align-items-start">
                                                                    <div>
                                                                        <span className="hover-arrow">DesignStream Subscription</span>

                                                                        <ul className="list-unstyled mt-2">
                                                                            <li className="d-flex py-1">
                                                                                <div className="icon-xl mr-2 text-primary-3">
                                                                                    <IconCheck className="injected-svg icon bg-success" />
                                                                                </div>
                                                                                <small>Unlimited UX / UI Tasks</small>
                                                                            </li>
                                                                            <li className="d-flex py-1">
                                                                                <div className="icon-xl mr-2">
                                                                                    <IconCheck className="injected-svg icon bg-success" />
                                                                                </div>
                                                                                <small>Unlimited Revisions</small>
                                                                            </li>
                                                                            <li className="d-flex py-1">
                                                                                <div className="icon-xl mr-2">
                                                                                    <IconCheck className="injected-svg icon bg-success" />
                                                                                </div>
                                                                                <small>2 Days Turnaround Time</small>
                                                                            </li>
                                                                            <li className="d-flex py-1">
                                                                                <div className="icon-xl mr-2">
                                                                                    <IconCheck className="injected-svg icon bg-success" />
                                                                                </div>

                                                                                <small>Project Management</small>
                                                                            </li>
                                                                            {/*                                                                             <img className="mt-2" src="https://www.datocms-assets.com/16499/1699565328-design-stream.png" />
                                                                             */}{" "}
                                                                        </ul>

                                                                        <img src={DesignStream}className="mt-2"/>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>

                                                        <div className="col dstream-box">
                                                            <div className="dropdown-single-item h-100">
                                                                <Link to="/ai-ux-design-review" className="list-group-item-action">
                                                                    <div>
                                                                        <span className="hover-arrow">
                                                                            <span className="text-primary">⚡ Fast</span> UX Design Review <span className="new-notification bg-primary-3">-40%</span>{" "}
                                                                        </span>
                                                                        <small>Get a 60+ page review of your product to eliminate all UX issues & skyrocket conversion, activation and retention.</small>
                                                                        <div style={{ maxWidth: "240px" }}>
                                                                            <img className="mt-2 h-100" src={UXReview} />
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>

                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="nav-item dropdown area-hover" ref={industriesRef}>
                                            <Link to="/#" className="nav-link" style={{ pointerEvents: "none" }}>
                                                Industries
                                                <i className="dd-icon"></i>
                                            </Link>
                                            <div className="dropdown-menu" ref={dropDownIndustriesRef}>
                                                <div className="dropdown-menu-content container">
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="dropdown-single-item">
                                                                <Link to="/fintech-design" className="list-group-item-action">
                                                                    {/* <i>
                                                                        <IconCard className="icon icon-md bg-primary" />
                                                                    </i> */}
                                                                    <div>
                                                                        <span className="hover-arrow">Fintech</span>
                                                                        <small>Making financial products crystal clear</small>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="dropdown-single-item">
                                                                <Link to="/ai-design-agency" className="list-group-item-action">
                                                                    {/* <i>
                                                                        <IconSmile className="icon icon-md bg-primary" />
                                                                    </i> */}
                                                                    <div>
                                                                        <span className="hover-arrow">AI</span>
                                                                        <small>Defining the future of humanity & digital experience</small>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="dropdown-single-item">
                                                                <Link to="/saas-design" className="list-group-item-action">
                                                                    {/* <i>
                                                                        <IconChart className="icon icon-md bg-primary" />
                                                                    </i> */}
                                                                    <div>
                                                                        <span className="hover-arrow">SaaS</span>
                                                                        <small>Designing analytics, AI platforms, dashboards</small>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="dropdown-single-item">
                                                                <Link to="/edtech-design" className="list-group-item-action">
                                                                    {/* <i>
                                                                        <IconEarth className="icon icon-md bg-primary" />
                                                                    </i> */}
                                                                    <div>
                                                                        <span className="hover-arrow">Edtech</span>
                                                                        <small>Making educational products fun and engaging</small>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="dropdown-menu">
                                                <div className="dropdown-menu-content">
                                                    <ul>
                                                        <li>
                                                            <Link to="/fintech-design" className="list-group-item-action">
                                                                <i>
                                                                    <IconCard className="icon icon-md bg-white" />
                                                                </i>
                                                                <div>
                                                                    <span>Fintech</span>
                                                                    <small>Making financial products crystal clear</small>
                                                                </div>
                                                            </Link>
                                                        </li>

                                                        <li>
                                                            <Link to="/ai-design-agency" className="list-group-item-action">
                                                                <i>
                                                                    <IconSmile className="icon icon-md bg-white" />
                                                                </i>
                                                                <div>
                                                                    <span>AI</span>
                                                                    <small>Defining the future of humanity</small>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/saas-design" className="list-group-item-action">
                                                                <i>
                                                                    <IconChart className="icon icon-md bg-white" />
                                                                </i>
                                                                <div>
                                                                    <span>SaaS</span>
                                                                    <small>Designing analytics, AI platforms, dashboards</small>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/edtech-design" className="list-group-item-action">
                                                                <i>
                                                                    <IconEarth className="icon icon-md bg-white" />
                                                                </i>
                                                                <div>
                                                                    <span>Edtech</span>
                                                                    <small>Making educational products fun and engaging</small>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div> */}
                                        </li>

                                        {/* <li className="nav-item">
                                            <Link to="/ux-consulting" className="nav-link">
                                            UX Consulting
                                            </Link>
                                        </li> */}

                                        <li className="nav-item dropdown area-hover" ref={aboutUsRef}>
                                            <Link to="/#" className="nav-link" style={{ pointerEvents: "none" }}>
                                                About Us
                                                <i className="dd-icon"></i>
                                            </Link>
                                            <div className="dropdown-menu" ref={dropDownAboutUsRef}>
                                                <div className="dropdown-menu-content container">
                                                    <div className="row align-items-center">
                                                        <div className="col">
                                                            <div className="dropdown-single-item">
                                                                <Link to="/about-us" className="list-group-item-action">
                                                                    <div>
                                                                        <span className="hover-arrow">Our Story & Why Us</span>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="dropdown-single-item">
                                                                <Link to="/ux-design-process" className="list-group-item-action">
                                                                    {/* <i>
                                                                    <IconThunder className="icon icon-md bg-primary" />
                                                                </i> */}
                                                                    <div>
                                                                        <span className="hover-arrow">Our UX Design Process</span>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* 
                                            <div className="dropdown-menu">
                                                <div className="dropdown-menu-content">
                                                    <ul>
                                                        <li>
                                                            <Link to="/about-us" className="list-group-item-action">
                                                                <i>
                                                                    <IconBuilding className="icon icon-md bg-white" />
                                                                </i>
                                                                <div>
                                                                    <span>Our Story</span>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/ux-design-process" className="list-group-item-action">
                                                                <i>
                                                                    <IconThunder className="icon icon-md bg-white" />
                                                                </i>
                                                                <div>
                                                                    <span>UX Design Process</span>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div> 
                                            */}
                                        </li>

                                        <li className="nav-item dropdown area-hover" ref={uxResourcesRef}>
                                            <Link to="/#" className="nav-link" style={{ pointerEvents: "none" }}>
                                                UX Resources
                                                <i className="dd-icon"></i>
                                            </Link>

                                            <div className="dropdown-menu" ref={dropDownUxResourcesRef}>
                                                <div className="dropdown-menu-content container">
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="dropdown-single-item h-100">
                                                                {/* <a href="https://app.adamfard.com/ai-color-generator" className="list-group-item-action align-items-start" target="_blank">
                                                                    <div>
                                                                        <span className="hover-arrow">AI Color Generator</span>
                                                                        <small>Generate color palettes with AI</small>
                                                                    </div>
                                                                </a> */}

                                                                {/* <a href="https://app.adamfard.com/startup-ideas-generator" className="list-group-item-action align-items-start" target="_blank">
                                                                    <div>
                                                                        <span className="hover-arrow">Startup Ideas Generator</span>
                                                                        <small>Ideas for your next startup</small>
                                                                    </div>
                                                                </a> */}

                                                                <a href="https://adamfard.com/ai-ux-ebook" className="list-group-item-action align-items-start" target="_blank">
                                                                    <div>
                                                                        <span className="hover-arrow">AI + UX eBook</span>
                                                                        <small>Maximize Your AI Knowledge</small>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="dropdown-single-item h-100">
                                                                <Link to="/blog" className="list-group-item-action align-items-start">
                                                                    {/*  <i>
                                                                        <IconBook className="icon icon-md bg-primary" />
                                                                    </i> */}
                                                                    <div>
                                                                        <span className="hover-arrow">UX Blog</span>
                                                                        <small>Articles and Stories</small>
                                                                    </div>
                                                                </Link>

                                                                <Link to="/download-ux-resources" className="list-group-item-action align-items-start">
                                                                    {/*  <i>
                                                                        <IconDownload className="icon icon-md bg-primary" />
                                                                    </i> */}
                                                                    <div>
                                                                        <span className="hover-arrow">See All Downloads</span>
                                                                        <small>UX Templates, Checklists & Guides</small>
                                                                    </div>
                                                                </Link>
                                                            </div>

                                                            {/* <div className="dropdown-single-item">
                                                                <Link to="/download-ux-resources/ux-research-plan-template" className="list-group-item-action">
                                                                    <div>
                                                                        <span className="hover-arrow">UX Research Plan Template</span>
                                                                         <img className="mt-2 border border-1 border-gray rounded" src="https://www.datocms-assets.com/16499/1652748861-ux-research-plan-template-cover.png" style={{maxWidth:"240px", aspectRatio:""}}/>
                                                                    </div>
                                                                </Link>
                                                            </div> */}
                                                        </div>
                                                        <div className="col dstream-box">
                                                            <div className="dropdown-single-item">
                                                                <a href="https://uxpilot.ai?source=adamfard.com" target="_blank" className="list-group-item-action">
                                                                    <div>
                                                                        <span className="hover-arrow">UX Pilot AI</span>
                                                                        <small>Generate UX/UI Superfast!</small>
                                                                        <div className="border border-1 border-gray rounded o-hidden mt-2" style={{ maxHeight: "150px" }}>
                                                                            <ReactPlayer
                                                                                url="https://d2r1ah7oa8r7ja.cloudfront.net/videos/UX Pilot - Hifi UI Design.mp4"
                                                                                width="100%"
                                                                                height="auto"
                                                                                muted={true}
                                                                                loop={true}
                                                                                playing={true}
                                                                                playsinline={true}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="dropdown-menu">
                                                <div className="dropdown-menu-content">
                                                    <ul>
                                                        {/* <li>
                            <Link to="/ux-resources">
                              <i>
                                <IconBookMenu />
                              </i>
                              <div>
                                <span>Resou rces</span>
                                <small>Overview all UX Resources</small>
                              </div>
                            </Link>
                          </li> */}
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>

                                        {/*                   <li className="nav-item">
                                                <Link to="/careers" className="nav-link">
                                                Jobs <span className="badge badge-primary">hiring</span>
                                                </Link>
                                            </li> */}

                                        <li className="nav-item" style={{ marginTop: "-2px" }}>
                                            <SearchNavButton />
                                        </li>
                                        <div className="dropdown-overlay" ref={backDropRef}></div>
                                    </ul>
                                </div>

                                {/* <CalendlyPopupButton /> */}
                                
                                <Link id="freeconsultation" to="/contact-us" className="btn btn-primary ml-lg-3">
                                    Contact Us
                                </Link>
                            </div>
                        </>
                    )}

                    <div className={`d-lg-none mobile-menu ${toggleMobileMenu ? "show " : ""}`}>
                        <div>
                            <ul className="navbar-nav">
                                <li>
                                    <p className="opacity-50 mb-0 pb-0">UX Services</p>
                                </li>
                                <li className="nav-item">
                                    <Link to="/ux-ui-design-services" className="nav-link">
                                        Research & UX / UI Design
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/unlimited-ux-ui-design-subscription" className="nav-link">
                                        Unlimited Subscription
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/fast-ux-design-review" className="nav-link">
                                        Fast UX Review
                                    </Link>
                                </li>

                                <li>
                                    <p className="opacity-50 mt-4 mb-0 pb-0">Case Studies</p>
                                </li>

                                <li className="nav-item">
                                    <Link to="/ux-projects" className="nav-link">
                                        Past Projects
                                    </Link>
                                </li>
                                <li>
                                    <p className="opacity-50 mt-4 mb-0 pb-0">Industries</p>
                                </li>
                                <li className="nav-item">
                                    <Link to="/ai-design-agency" className="nav-link">
                                        AI
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/fintech-design" className="nav-link">
                                        Fintech
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/saas-design" className="nav-link">
                                        SaaS
                                    </Link>
                                </li>
                                <li>
                                    <p className="opacity-50 mt-4 mb-0 pb-0">About Us</p>
                                </li>
                                <li className="nav-item">
                                    <Link to="/about-us" className="nav-link">
                                        Our Story & Why Us
                                    </Link>
                                </li>
                                <li>
                                    <p className="opacity-50 mt-4 mb-0 pb-0">UX Resources</p>
                                </li>
                                <li className="nav-item">
                                    <Link to="/blog" className="nav-link">
                                        <span>UX Blog</span>
                                    </Link>
                                </li>
                                <li className="nav-item pb-7 mb-7">
                                    <Link to="/download-ux-resources" className="nav-link">
                                        <span>Downloads</span>
                                        {/*<p>Templates, Checklists & Guides</p>*/}
                                    </Link>
                                </li>
                                {/* <li className="nav-item">
                                    <a href="https://uxpilot.ai" target="_blank" className="nav-link">
                                        <span>UX Pilot</span>
                                    </a>
                                </li> */}
                                {/* <li className="nav-item">
                                    <Link to="/careers" className="nav-link">
                                        Careers
                                    </Link>
                                    </li> */}
                            </ul>
                        </div>
                        <div className="cta-menu">
                            <p>10x your product experience:</p>
                            <Link to="/contact-us" className="btn btn-lg btn-white">
                                Contact Us
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
